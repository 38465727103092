"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAddressSuggestions = exports.CONSUMER_SMARTY_STREETS_AUTOCOMPLETE = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _autocompleteSamples = require("./autocompleteSamples");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var CONSUMER_SMARTY_STREETS_AUTOCOMPLETE = "CONSUMER_SMARTY_STREETS_AUTOCOMPLETE";
exports.CONSUMER_SMARTY_STREETS_AUTOCOMPLETE = CONSUMER_SMARTY_STREETS_AUTOCOMPLETE;
var cache = {
  '': []
};

var getAddressSuggestions = function getAddressSuggestions(address, maxResults, loadingFnStop) {
  return function (dispatch) {
    var callback = function callback(response, autocompleteAvailable) {
      dispatch({
        type: CONSUMER_SMARTY_STREETS_AUTOCOMPLETE,
        payload: response.data
      });
      loadingFnStop(autocompleteAvailable);
    };

    dispatch({
      type: CONSUMER_SMARTY_STREETS_AUTOCOMPLETE,
      payload: {
        suggestions: []
      }
    });

    if (!process.env.REACT_APP_SMARTY_STREETS_AUTOCOMPLETE_KEY) {
      var samples = (0, _autocompleteSamples.getAutocompleteSamples)(address);
      callback({
        data: samples
      }, true);
      return;
    }

    if (cache[address]) {
      callback({
        data: {
          suggestions: cache[address]
        }
      }, true);
      return;
    }

    _axios["default"].get('https://us-autocomplete-pro.api.smartystreets.com/lookup' + '?key=' + process.env.REACT_APP_SMARTY_STREETS_AUTOCOMPLETE_KEY + '&max_results=' + maxResults + '&search=' + address, {}).then(function (value) {
      if (value.data.suggestions) {
        cache[address] = value.data.suggestions;
      } else {
        cache[address] = [];
      }

      callback(value, true);
    })["catch"](function (reason) {
      callback({
        data: {
          suggestions: []
        }
      }, reason.response.status !== 402);
    });
  };
};

exports.getAddressSuggestions = getAddressSuggestions;