import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import uuid from "uuid";
import Log from "@wisetack/shared-ui/utils/Log";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { submitData } from "../store/actions/consumerActions";

class ConsumerApplicationExpiredPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    logAmplitudeEvent("Application Expired", {});
    if (this.props.location.pathname === "/timeout") {
      const requestId = uuid.v4();
      this.props.submitData(this.props.loanAppId, { lastAccessedOn: new Date('2000-01-01T00:00:00') }, requestId)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.submitDataRequestId && this.props.submitDataRequestId !== prevProps.submitDataRequestId) {
      this.props.history.push("/" + this.props.token);
    }
  }

  render() {
    Log.info(this.props, `ConsumerApplicationExpiredPage props`);

    return (
      <Container>
        <PageHeader progress="100%">
          <div>This application has expired</div>
          <div style={{textAlign: "center"}}>
            Please contact the merchant directly to start a new application.
          </div>
        </PageHeader>
        {this.props.isLoading && <Loader />}
      </Container>
    );
  }
}

ConsumerApplicationExpiredPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  token: state.consumer.token,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  status: state.consumer.status,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  submitDataRequestId: state.consumer.submitDataRequestId
});

export default connect(
  mapStateToProps,
  { submitData }
)(ConsumerApplicationExpiredPage);
