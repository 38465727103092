import React from 'react'
import Log from '@wisetack/shared-ui/utils/Log'
import PlaidLink from '@wisetack/shared-ui/components/PlaidLink'
import Loader from '@wisetack/shared-ui/components/Loader'
import Header from './Header'
import { connect } from 'react-redux'
import {
    plaidRegistration,
    getPlaidItems,
    getPlaidPI,
    getPlaidIncome,
    getPlaidAccount,
    getPlaidBalance,
    deletePlaidItem,
    clearData

} from '../store/actions/plaidActions'

class Plaid extends React.Component {
    state = {
        token: null,
        customerId: 'customer_1',
        response: {}
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        let response = {};
        if (nextProps.registrationResult) {
            response.registrationResult = nextProps.registrationResult;
        }
        if (nextProps.personalInformation) {
            response.personalInformation = nextProps.personalInformation;
        }
        if (nextProps.accounts) {
            response.accounts = nextProps.accounts;
        }
        if (nextProps.income) {
            response.income = nextProps.income;
        }
        if (nextProps.balance) {
            response.balance = nextProps.balance;
        }
        this.setState({ response });
    }

    handleOnExit = (err, metadata) => {
        Log.info(err, `Plaid error on exit`);
        Log.info(metadata, `Plaid metadata on exit`);
    }

    handleOnEvent = (eventName, metadata) => {
        Log.info(eventName, `Plaid event`);
        Log.info(metadata, `Plaid metadata on event`);
    }

    handleOnSuccess = async (token, metadata) => {
        Log.info(token, `Plaid token`);
        Log.info(metadata, `Plaid metadata on success`);
        this.setState({ token });
        this.props.plaidRegistration(this.state.customerId, token)
    }

    handleOnCustomerChange = (event) => {
        event.preventDefault();
        this.setState({ customerId: event.target.value })
    }

    handleOnListClick = () => {
        this.props.getPlaidItems(this.state.customerId);
    }

    handleOnClearClick = () => {
        this.setState({
            customerId: '',
        });
        this.props.clearData();
    }

    handleOnPIClick = async (item) => {
        this.props.getPlaidPI(item.customerId, item.institutionId);
    }

    handleOnIncomeClick = async (item) => {
        this.props.getPlaidIncome(item.customerId, item.institutionId);
    }

    handleOnAccountClick = async (item) => {
        this.props.getPlaidAccount(item.customerId, item.institutionId);
    }

    handleOnBalanceClick = async (item) => {
        this.props.getPlaidBalance(item.customerId, item.institutionId);
    }

    handleOnRemoveClick = async (item) => {
        this.props.deletePlaidItem(item.customerId, item.institutionId);
    }

    render() {
        Log.info(this.props, `Plaid props`);

        const ErrorMessage = () => {
            return this.props.errorMessage ?
                <div className="alert alert-danger">{this.state.errorMessage || this.props.errorMessage}</div> : null
        }
        const Response = () => {
            return Object.keys(this.state.response).length > 0 ? <div className="alert">
                <pre>{JSON.stringify(this.state.response, null, 2)}</pre>
            </div> : null
        }
        const Loading = () => {
            return this.props.isLoading ? <Loader/> : null
        }
        return (
            <>
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header card-header-info">Plaid Link</div>
                                <div className="card-body">
                                    <h4 className="card-title">Creating Items with Plaid Link</h4>
                                    <p>Open Link and connect a bank account to Plaid ({process.env.REACT_APP_PLAID_ENV})</p>
                                    <div className="form-group">
                                        <label className="bmd-label-floating" htmlFor="#customer">Customer ID</label>
                                        <input className="form-control" id="#customer" value={this.state.customerId} onChange={this.handleOnCustomerChange} />
                                    </div>
                                    <span className="btn-toolbar">
                                        <PlaidLink
                                            disabled={!this.state.customerId}
                                            className="btn btn-primary"
                                            clientName="Wisetack"
                                            env={process.env.REACT_APP_PLAID_ENV}
                                            publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
                                            product={["auth", "transactions", "income", "identity"]}
                                            onExit={this.handleOnExit}
                                            onEvent={this.handleOnEvent}
                                            onSuccess={this.handleOnSuccess}>
                                            Plaid Link &rarr;
                                    </PlaidLink>
                                        <button className="btn btn-success" onClick={this.handleOnListClick}>Registrations List</button>
                                        <button className="btn btn-danger btn-round" onClick={this.handleOnClearClick}>Clear</button>
                                    </span>
                                    <Loading />
                                    <ErrorMessage />
                                    <Response />
                                    {this.props.registrationList.length > 0 &&
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover table-sm">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Customer ID</th>
                                                        <th scope="col">Institution ID</th>
                                                        <th scope="col">Operation</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.registrationList.map(item => <tr key={item.itemId}>
                                                        <td>{item.customerId}</td>
                                                        <td>{item.institutionId}</td>
                                                        <td>
                                                            <button className="btn btn-primary btn-fab btn-fab-mini btn-round" title="Personal Information">
                                                                <i className="material-icons" onClick={() => { this.handleOnPIClick(item) }}>person</i>
                                                            </button>
                                                            <button className="btn btn-success btn-fab btn-fab-mini btn-round" title="Income">
                                                                <i className="material-icons" onClick={() => { this.handleOnIncomeClick(item) }}>money</i>
                                                            </button>
                                                            <button className="btn btn-info btn-fab btn-fab-mini btn-round" title="Accounts">
                                                                <i className="material-icons" onClick={() => { this.handleOnAccountClick(item) }}>credit_card</i>
                                                            </button>
                                                            <button className="btn btn-warning btn-fab btn-fab-mini btn-round" title="Accounts Balance">
                                                                <i className="material-icons" onClick={() => { this.handleOnBalanceClick(item) }}>account_balance</i>
                                                            </button>
                                                            <button className="btn btn-danger btn-fab btn-fab-mini btn-round" title="Remove Item">
                                                                <i className="material-icons" onClick={() => { this.handleOnRemoveClick(item) }}>delete</i>
                                                            </button>
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    registrationList: state.plaid.registrationList,
    errorMessage: state.plaid.errorMessage,
    isLoading: state.plaid.isLoading,
    registrationResult: state.plaid.registrationResult,
    personalInformation: state.plaid.personalInformation,
    accounts: state.plaid.accounts,
    income: state.plaid.income,
    balance: state.plaid.balance
})

export default connect(mapStateToProps, {
    plaidRegistration,
    getPlaidItems,
    getPlaidPI,
    getPlaidIncome,
    getPlaidAccount,
    getPlaidBalance,
    deletePlaidItem,
    clearData
})(Plaid);
