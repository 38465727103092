import React, {Component} from 'react'
import Script from 'react-load-script'
import PropTypes from 'prop-types'
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));

class InquiryLauncher extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            disabledButton: true,
            linkLoaded: false,
            scriptLoaded: false,
            initializeURL: 'https://cdn.withpersona.com/dist/persona-v4.4.0.js',
            activeInquiry: this.props.activeInquiry,
            piiData: this.props.piiData,
            onLoad: this.props.onLoad
        };

        this.onScriptError = this.onScriptError.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    static defaultProps = {
        env: process.env.REACT_APP_PLAID_ENV,
        frameAncestor: process.env.REACT_APP_PERSONA_FRAME_ANCESTOR
    };

    static propTypes = {
        env: PropTypes.oneOf(['sandbox', 'production']).isRequired,
        frameAncestor: PropTypes.string.isRequired,
        templateId: PropTypes.string.isRequired,
        activeInquiry: PropTypes.object,
        piiData: PropTypes.object,
        onFinish: PropTypes.func.isRequired,
        onInquiryFinishBefore: PropTypes.func.isRequired
    }

    onScriptError() {
        console.error('There was an issue loading the persona-v4.4.0.js script');
    }

    handleOnClick() {
        console.log(`Click to create Persona Inquiry verification`);
        const obj = {};
        const loggingObj = {};
        if (this.props.activeInquiry) {
            obj.inquiryId = this.props.activeInquiry.id;
            loggingObj.inquiryId = this.props.activeInquiry.id;
            if (this.props.activeInquiry.sessionToken) {
                obj.sessionToken = this.props.activeInquiry.sessionToken;
            }
        } else {
            obj.templateId = this.props.templateId;
            obj.referenceId = this.props.loanApplicationId;
            loggingObj.loanApplicationId = this.props.loanApplicationId;
        }
        obj.environment = this.props.env;
        if (this.props.frameAncestor) {
            obj.frameAncestors = [this.props.frameAncestor];
        }
        obj.fields = {
            nameFirst: this.props.piiData.nameFirst,
            nameLast: this.props.piiData.nameLast,
            birthdate: this.props.piiData.birthdate,
            addressStreet1: this.props.piiData.addressStreet1,
            addressCity: this.props.piiData.addressCity,
            addressSubdivision: this.props.piiData.addressSubdivision,
            addressPostalCode: this.props.piiData.addressPostalCode,
            addressCountryCode: this.props.piiData.countryCode,
            phoneNumber: this.props.piiData.phoneNumber,
            emailAddress: this.props.piiData.emailAddress
        }
        obj.onLoad = (_error) => {
            window.personaClient.open();
            this.props.onLoad();
        }
        obj.onEvent = (name, meta) => {
            switch (name) {
                case 'load':
                    logAmplitudeEvent("[Persona] Drivers License Front Page", loggingObj);
                    break;
                case 'document-camera-select':
                    logAmplitudeEvent("[Persona] Pressed Camera Button", loggingObj);
                    break;
                case 'document-camera-capture':
                    logAmplitudeEvent("[Persona] Pressed Take Photo Button", loggingObj);
                    break;
                case 'one-time-link-sent':
                    switch (meta.method) {
                        case 'qr':
                            logAmplitudeEvent("[Persona] Pressed QR Code on Another Device Page", loggingObj);
                            break;
                        case 'email':
                            logAmplitudeEvent("[Persona] Pressed Send Secure Link (Email) on Another Device Page", loggingObj);
                            break;
                        case 'sms':
                            logAmplitudeEvent("[Persona] Pressed Send Secure Link (SMS) on Another Device Page", loggingObj);
                            break;
                        default:
                            break;
                    }
                    break;
                case 'verification-change':
                    switch (meta.status) {
                        case 'submitted':
                            logAmplitudeEvent("[Persona] Pressed Use This Photo Button (Back ID)", loggingObj);
                            break;
                        case 'passed':
                            logAmplitudeEvent("[Persona] ID Submitted Page", loggingObj);
                            break;
                        case 'failed':
                            logAmplitudeEvent("[Persona] Couldn't verify photos Page", loggingObj);
                            break;
                        default:
                            break;
                    }
                    break;
                case 'complete':
                case 'success':
                case 'fail':
                case 'exit':
                    if (name === 'exit') {
                        logAmplitudeEvent("[Persona] Pressed Cross Button", loggingObj);
                    } else {
                        logAmplitudeEvent("[Persona] Pressed Done Button", loggingObj);
                    }
                    this.props.onInquiryFinishBefore();
                    sleep(500)
                        .then(value => this.props.onFinish(meta));
                    break;
                default:
                    break;
            }
        }
        window.personaClient = new window.Persona.Client(obj);

        this.setState({disabledButton: false, scriptLoaded: true});
    }

    render() {
        return (
            <>
                <div
                    data-test-id="start-inquiry"
                    onClick={this.handleOnClick}>
                    {this.props.children}
                </div>
                <Script
                    url={this.state.initializeURL}
                    onError={this.onScriptError}/>
            </>
        );
    }
}

export default InquiryLauncher;