"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAmplitudeUserProperty = exports.setAmplitudeUserId = exports.logAmplitudeEvent = exports.initAmplitude = void 0;

var _amplitude = _interopRequireDefault(require("amplitude-js/amplitude"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var initAmplitude = function initAmplitude(key) {
  _amplitude["default"].getInstance().init(key);
};

exports.initAmplitude = initAmplitude;

var setAmplitudeUserId = function setAmplitudeUserId(userId) {
  _amplitude["default"].getInstance().setUserId(userId);
};

exports.setAmplitudeUserId = setAmplitudeUserId;

var logAmplitudeEvent = function logAmplitudeEvent(eventType, eventProperties) {
  _amplitude["default"].getInstance().logEvent(eventType, eventProperties);
};

exports.logAmplitudeEvent = logAmplitudeEvent;

var setAmplitudeUserProperty = function setAmplitudeUserProperty(key, value) {
  var identify = new _amplitude["default"].Identify().set(key, value);

  _amplitude["default"].getInstance().identify(identify);
};

exports.setAmplitudeUserProperty = setAmplitudeUserProperty;