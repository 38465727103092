import React, {Fragment} from "react";
import styles from "./LoaderWithMessage.module.scss";
import Loader from "@wisetack/shared-ui/components/PulseLoader";

export function LoaderWithMessage({isLoading,duration, title, message}) {
    if (!isLoading) return null;
    if(!title) title = "Hang Tight!";
    if(!message) message = "We are reviewing your application. Your options will display in a moment...";
    if(!duration) duration = "short"


    if (duration === 'long')
        return (
            <div>
                <Fragment><div className={styles.title}>{title}</div></Fragment>
                <Loader />
                <div style={{ textAlign: "center" }}>
                    {message}
                </div>
            </div>
        )
    else  return (
        <div>
            <Loader />
            <div className={styles.message}>Wait a moment please...</div>
        </div>
    );
}
