import styles from "./ConsumerCreditFrozenPage.module.scss";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import classNames from "classnames";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { loadToken } from "@wisetack/shared-ui/utils/localStorage";
import Loader from "@wisetack/shared-ui/components/PulseLoader";

class ConsumerCreditFrozenPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        logAmplitudeEvent("Credit Frozen Page", {});
    }

    continue = () => {
        this.props.history.push("/" + loadToken());
    }

    render() {

        let btnDisabled = false
        const btnClasses = classNames({
          btn: true,
          "btn-block": true,
          "btn-disabled": btnDisabled,
          [styles.buttonDisabled]: btnDisabled,
          [styles.buttonEnabled]: !btnDisabled
        });

        const LoaderWithMessage = () => {
            if (!this.props.isLoading) return null;
            return (
              <div>
                <Loader />
                <div className={styles.message}>Wait a moment please...</div>
              </div>
            );
          };

        return (
            <Container>
                <PageHeader progress="20%">
                    <Fragment>
                        Please unfreeze your 
                        <br/>
                        credit report
                    </Fragment>
                    <Fragment/>
                </PageHeader>
                <LoaderWithMessage />
                { !this.props.isLoading ? (
                    <div>
                        <div className={styles.content}>
                            <p>It looks like your TransUnion credit report is frozen. 
                                To continue, please unfreeze it through one of the following:</p>
                            <p>
                                <b>Visit TransUnion's website:</b>
                                <br/>
                                <a href="https://www.transunion.com/credit-freeze" target="_blank" rel="noopener noreferrer">
                                    https://www.transunion.com/credit-freeze
                                </a>
                            </p>
                            <p><b>Call TransUnion</b> at <a href="tel:888-909-8872">888-909-8872</a></p>
                            <p>You can also unfreeze through a credit monitoring service like Credit Karma.</p>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className={styles.score} style={{paddingBottom: "0px", marginBottom: "0px"}}>
                                    <span className={styles.scoreTxt}>
                                        <b>Continue after unfreezing your credit report</b>
                                    </span>
                                </div>
                                <div className={styles.score}>
                                    <span className={classNames("material-icons", styles.scoreIcon)}>
                                        lock_outline
                                    </span>{" "}
                                    <span className={styles.scoreTxt}>
                                        Continuing <b>will not</b> impact your credit&nbsp;score.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <div className="col">
                                <button data-test-id="continue" className={btnClasses} onClick={this.continue}>
                                    CONTINUE
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (<div style={{ paddingBottom: "15px" }} />)}
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    loanID: state.consumer.loanAppId,
    isLoading: state.consumer.isLoading
})

export default connect(
    mapStateToProps,
    {}
)(ConsumerCreditFrozenPage);
