import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerDeclinedMaxPage.module.scss";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import PdfModalZoom from "@wisetack/shared-ui/components/PdfModalZoom";

const pageName = "Concurrent Loan Limit Page"

class ConsumerDeclinedMaxPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName
        }
        logAmplitudeEvent(pageName, this.logProps);
    }

    modalOnClick = eventType => () => {
        logAmplitudeEvent(eventType, this.logProps);
    };

    render() {
        return (
            <Container>
                {this.props.adverseAction &&
                    <PdfModalZoom name="adverseActionModal" data={this.props.adverseAction} headerTitle="Adverse Action"/>
                }
                {this.props.h5CreditScoreDisclosure &&
                    <PdfModalZoom name="h5CreditScoreDisclosureModal" data={this.props.h5CreditScoreDisclosure} headerTitle="Credit Score Disclosure"/>
                }
                <PageHeader progress="10%">
                    <div className={styles.title}>
                        Thanks for considering us again
                    </div>
                    <Fragment/>
                </PageHeader>
                <div className={styles.content}>
                    <p>Unfortunately, we're not able to proceed, because you've reached the maximum amount on your Wisetack loans. We've emailed you the following documents with more information.</p>
                    <p>
                        {this.props.adverseAction &&
                            <span data-toggle="modal" data-target="#adverseActionModal"
                                  onClick={this.modalOnClick("Opened Notice of Action Link")}>
                                Notice of Action
                            </span>
                        }
                        <br/>
                        {this.props.h5CreditScoreDisclosure &&
                            <span data-toggle="modal" data-target="#h5CreditScoreDisclosureModal"
                                  onClick={this.modalOnClick("Opened Credit Score Disclosure (H5) Link")}>
                                Credit Score Disclosure
                            </span>
                        }
                    </p>
                    <p>Thank you for being a valued customer. We hope to serve you again in the future.</p>
                </div>
            </Container>
        );
    }
}

ConsumerDeclinedMaxPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  status: state.consumer.status,
  adverseAction: state.consumer.adverseAction,
  h5CreditScoreDisclosure: state.consumer.h5CreditScoreDisclosure
});

export default connect(
  mapStateToProps,
  {}
)(ConsumerDeclinedMaxPage);