import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";

import styles from "./PrequalFrozenPage.module.scss";
import usePrequalFlow from "../hooks/usePrequalFlow";
import {prequalGet} from "../store/actions/consumerActions";

const pageName = 'Prequal Frozen Page'

function PrequalFrozenPage({prequalGet}) {
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');
    const {logAmplitude} = usePrequalFlow(prequalGet, newRequest);

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    return <Container>
        <PageHeader progress="100%">
            <div>Please unfreeze your credit&nbsp;report</div>
            <div/>
        </PageHeader>
        <div className={styles.message}/>
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        { !requestInProgress &&
            <>
                <div className={styles.message}>
                    It looks like your TransUnion credit report is frozen. Please reapply after unfreezing through one of&nbsp;the&nbsp;following:
                </div>
                <div className={styles.message}>
                    <b>Visit TransUnion’s website:</b><br/><a href="http://www.transunion.com/credit-freeze" target="_blank" rel="noopener noreferrer">http://www.transunion.com/credit-freeze</a>
                </div>
                <div className={styles.message}>
                    <b>Call TransUnion at</b>
                    <div className={styles.phone}>888-909-8872</div>
                </div>
                <div className={styles.message}>
                    You can also unfreeze through a credit monitoring service like Credit&nbsp;Karma.
                </div>
            </>
        }
        <Error pageName={pageName}>{requestError || errorMessage}</Error>
    </Container>
}

export default connect(null, {prequalGet})(
    PrequalFrozenPage
);
