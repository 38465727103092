import React, {useCallback, useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import classNames from "classnames";
import debounce from 'lodash.debounce';

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Plan from "@wisetack/shared-ui/components/Plan";
import FormNumberInput from "@wisetack/shared-ui/components/FormNumberInput";

import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";

import {prequalGet, prequalPatch, prequalPatchSelectOffer} from "../store/actions/consumerActions";
import usePrequalFlow from "../hooks/usePrequalFlow";
import styles from "./PrequalOffersPage.module.scss";
import PromptModal from "./PromptModal";
import useFocus from "@wisetack/shared-ui/utils/useFocus";
import {formatExpDate} from "../utils/format";

const minimum = 500;

const toNumber = (amount) => {
    return Number(amount.replace(/\D/g,''))
}

const AS_CALCULATOR_MODE = false
const pageName = 'Prequal Offers Page';

const Amount = ({amount, maximumLoanAmount, onAmountEntered, requestInProgress}) => {
    const [inputRef, setFocus] = useFocus();

    const maximum = toNumber(maximumLoanAmount);
    const [value, setValue] = React.useState();
    const [errors, setErrors] = React.useState({});

    const showError = () => {
        setErrors({'loanAmount': `Enter amount between $500 and ${maximumLoanAmount}`});
        setTimeout(() => {
            setErrors({'loanAmount': ''});
        }, 1500)
    }

    const checkAmount = (val, max, min, skipErrorMessage) => {
        if (!val || !max) {
            return false;
        }
        let amount = toNumber(val);
        if (max && amount > max) {
            !skipErrorMessage && showError()
            return false;
        }
        if (min && amount < min) {
            !skipErrorMessage && showError()
            return false;
        }
        return true;
    }

    const debouncedAmountEntered = useCallback(
        debounce(newAmount => {
            if (checkAmount(newAmount, maximum, minimum)) {
                onAmountEntered(newAmount)
            }
        }, 2000),
        [], // will be created only once initially
    );

    useEffect(() => {
        if (!requestInProgress) {
            setTimeout(setFocus,500);
        }
    }, [requestInProgress, setFocus])

    useEffect(() => {
        let newAmount = amount
        if (newAmount) {
            newAmount = newAmount.replace(/\D/g, "")
            if (newAmount !== amount) {
                setValue(newAmount);
            }
        }
    }, [amount])

    const amountClasses = classNames({
        "form-control": true,
        [styles.enabled]: true,
        [styles.invalidDisabled]: true,
        [styles.amount]: true
    });

    const amountLabelClasses = classNames({
        "bmd-label-floating": true,
        [styles.amountLabel]: true
    })

    const handleOnChange = e => {
        let val = e.target.value;
        if (!checkAmount(val, maximum)) {
            return;
        }
        setValue(val);
        if (AS_CALCULATOR_MODE) {
            debouncedAmountEntered(val)
        }
    }

    const handleKeypress = e => {
        if (e.code === "Enter") {
            if (!checkAmount(value, maximum, minimum)) {
                return;
            }
            onAmountEntered(value);
        }
    }

    const btnDisabled = requestInProgress || !checkAmount(value, maximum, minimum, true)

    const btnClasses = classNames({
        col: true,
        btn: true,
        "btn-block": true,
        "btn-disabled": btnDisabled,
        [styles.buttonDisabled]: btnDisabled,
        [styles.buttonEnabled]: !btnDisabled
    });

    return <>
        {errors.loanAmount ?
            <div className={styles.errorMessage}>{errors.loanAmount}</div> :
            <div className={styles.amountTitle}>Enter amount to see estimated&nbsp;offers:</div>
        }
        <div className={classNames("row", styles.amountGroup)}>
            <div className={classNames("col",styles.amountInput)}>
                <FormNumberInput
                    disabled={requestInProgress}
                    className={amountClasses}
                    labelClasses={amountLabelClasses}
                    name="loanAmount"
                    label=""
                    value={value}
                    onChange={handleOnChange}
                    onKeyPress={handleKeypress}
                    inputMode='numeric'
                    pattern='[0-9]*'
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={0}
                    allowNegative={false}
                    innerRef={inputRef}
                />
            </div>
            <button
                data-test-id="update"
                className={btnClasses}
                onClick={() => handleKeypress({code: "Enter"})}
            >
                UPDATE
            </button>
        </div>
    </>
}

const PromptModalWrapper = ({children}) => {
    return <div data-toggle="modal" data-target="#whatsNextModal">
        {children}
    </div>
}

const PrequalDetails = ({maximumLoanAmount, expDate, aprRange, termRange}) => {

    const formattedExpDate = formatExpDate(expDate)

    return <div className={styles.details}>
        <div className={styles.title}>You’re prequalified for up to</div>
        <div className={styles.subtitle}>{maximumLoanAmount}</div>
        <div className={styles.separator}/>
        <div className={styles.additional}>
            <div className={styles.key}>APR:</div>
            <div className={styles.value}>{aprRange}</div>
            <div className={styles.key}>Term length:</div>
            <div className={styles.value}>{termRange}</div>
            <div className={styles.key}>Expiration date:</div>
            <div className={styles.value}>{formattedExpDate}</div>
        </div>
    </div>
}

function PrequalOffersPage({prequalGet, prequalPatch, prequalPatchSelectOffer}) {
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const plans = useSelector(state => state.consumer.plans);
    const status = useSelector(state => state.consumer.status);
    const maximumLoanAmount = useSelector(state => state.consumer.maximumLoanAmount);
    const requestedLoanAmount = useSelector(state => state.consumer.requestedLoanAmount);
    const expDate = useSelector(state => state.consumer.expDate);
    const selectedOfferId = useSelector(state => state.consumer.selectedOfferId);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');
    const {applicationId, logAmplitude} = usePrequalFlow(prequalGet, newRequest);

    const reapply = () => {
        prequalPatch(
            applicationId,
            {reapply: true},
            newRequest()
        );
        logAmplitude(pageName, 'Reapply requested')
    }

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    useEffect(() => {
        if (status === 'EXPIRED') {
            reapply();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    const handleOnPlanClick = (item) => {
        if (item && item.id && status !== 'EXPIRED') {
            if (selectedOfferId && selectedOfferId === item.id) {
                return;
            }
            prequalPatchSelectOffer(
                applicationId,
                {selectedOfferId: item.id},
                newRequest()
            );
            logAmplitude(pageName, `Offer clicked`, {selectedOfferId: item.id})
        }
    }

    const handleAmountEntered = (amount) => {
        prequalPatch(
            applicationId,
            {requestedLoanAmount: toNumber(amount)},
            newRequest()
        );
        logAmplitude(pageName, 'Requested loan amount submitted', {amount})
    }

    const getAprRange = (plans) => {

        if (!plans || plans.length === 0) {
            return ''
        }
        let left = plans[0].apr
        let right = plans[plans.length - 1].apr
        return `${left}%-${right}%`
    }

    const getTermsRange = (plans) => {

        if (!plans || plans.length === 0) {
            return ''
        }
        let left = plans[0].months
        let right = plans[plans.length - 1].months
        return `${left}-${right} months`
    }

    return <Container>
        <PageHeader progress="20%">
        </PageHeader>
        <PromptModal/>
        <div className={styles.wrapper}>
            {maximumLoanAmount &&
                <div>
                    <PrequalDetails maximumLoanAmount={maximumLoanAmount}
                                    expDate={expDate}
                                    aprRange={getAprRange(plans)}
                                    termRange={getTermsRange(plans)}
                    />
                    <PromptModalWrapper>
                        <div className={styles.prompt}>
                            What’s next?
                        </div>
                    </PromptModalWrapper>
                </div>
            }
            {requestedLoanAmount && maximumLoanAmount &&
            <Amount
                amount={requestedLoanAmount}
                maximumLoanAmount={maximumLoanAmount}
                onAmountEntered={handleAmountEntered}
                requestInProgress={requestInProgress}
            />
            }
            <LoaderWithMessage loading={!!requestId && requestInProgress}/>
            <Error pageName={pageName}>{requestError || errorMessage}</Error>
            <div className={styles.plans}>
                {!requestInProgress && plans &&
                plans.map((item) => (
                    <PromptModalWrapper key={item.id}>
                        <Plan
                            item={item}
                            onClick={() => handleOnPlanClick(item)}
                        />
                    </PromptModalWrapper>
                ))
                }
            </div>
            {
                expDate &&
                <>
                    <div className={styles.terms}>
                        Your prequalification terms are valid until {expDate}.
                        Approval and terms are not guaranteed; see actual terms during loan application.
                        New borrowing activity or a change in your status with Wisetack may affect the above prequalification.
                        Wisetack loans are made by Hatch Bank.
                    </div>
                </>
            }
        </div>

    </Container>

}

export default connect(null, {prequalGet, prequalPatch, prequalPatchSelectOffer})(
    PrequalOffersPage
);
