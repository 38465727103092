/* eslint-disable no-restricted-globals */
import React, {Component} from "react";
import {parse} from 'query-string';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import Home from "./views/Home";
import Plaid from "./views/Plaid";
import About from "./views/About";
import ConsumerEntryPage from "./views/ConsumerEntryPage";
import ConsumerChoosePlanPage from "./views/ConsumerChoosePlanPage";
import ConsumerReviewPlanPage from "./views/ConsumerReviewPlanPage";
import ConsumerLinkBankPage from "./views/ConsumerLinkBankPage";
import ConsumerPinPage from "./views/ConsumerPinPage";
import ConsumerRejectedPage from "./views/ConsumerRejectedPage";
import ConsumerErrorPage from "./views/ConsumerErrorPage";
import LandingPage from "./views/LandingPage";
import BlankPage from "@wisetack/shared-ui/views/BlankPage";
import ConsumerPurchaseCompletePage from "./views/ConsumerPurchaseCompletePage";
import ConsumerPurchaseCompleteLockPage from "./views/ConsumerPurchaseCompleteLockPage";
import ConsumerApplicationExpiredPage from "./views/ConsumerApplicationExpiredPage";
import ConsumerDeclinedPage from "./views/ConsumerDeclinedPage";
import MerchantDeclinedPage from "./views/MerchantDeclinedPage";
import MerchantExpiredPage from "./views/MerchantExpiredPage";
import ConsumerDeclinedMaxPage from "./views/ConsumerDeclinedMaxPage";
import {initAmplitude, logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import ConsumerPhonePage from "./views/ConsumerPhonePage";
import ConsumerCreditFrozenPage from "./views/ConsumerCreditFrozenPage";
import ConsumerMoreInfoRequiredPage from "./views/ConsumerMoreInfoRequiredPage";
import ConsumerTermsAgreementPage from "./views/agreements/ConsumerTermsAgreementPage";
import ConsumerPrivacyAgreementPage from "./views/agreements/ConsumerPrivacyAgreementPage";
import ConsumerEsignAgreementPage from "./views/agreements/ConsumerEsignAgreementPage";
import ConsumerAutopayAgreementPage from "./views/agreements/ConsumerAutopayAgreementPage";
import ConsumerCreditScoreAgreementPage from "./views/agreements/ConsumerCreditScoreAgreementPage";
import ConsumerLoanAgreementPage from "./views/agreements/ConsumerLoanAgreementPage";
import MerchantAgreementPage from "./views/agreements/MerchantAgreementPage";
import ConsumerConfirmationPage from "./views/ConsumerConfirmationPage";
import AutopayConfirmationPage from "./views/AutopayConfirmationPage";
import ConsumerConfirmationDeniedPastPage from "./views/ConsumerConfirmationDeniedPastPage";
import ConsumerConfirmationDeniedFuturePage from "./views/ConsumerConfirmationDeniedFuturePage";
import ConsumerConfirmationReminder from "./views/ConsumerConfirmationReminder";
import PaymentCalculatorPage from "./views/PaymentCalculatorPage";
import ConsumerCanceledPage from "./views/ConsumerCanceledPage";
import ConsumerRefundedPage from "./views/ConsumerRefundedPage";
import OfferLockPage from "./views/OfferLockPage";
import SessionExpiredPage from "@wisetack/shared-ui/views/SessionExpiredPage";
import PrequalSignupPage from "./views/PrequalSignupPage";
import PrequalPhonePage from "./views/PrequalPhonePage";
import PrequalPinPage from "./views/PrequalPinPage"
import PrequalEntryPage from "./views/PrequalEntryPage"
import PrequalAddressPage from "./views/PrequalAddressPage"
import PrequalOffersPage from "./views/PrequalOffersPage"
import PrequalReapplyPage from "./views/PrequalReapplyPage"
import PrequalDeclinedPage from "./views/PrequalDeclinedPage"
import PrequalFrozenPage from "./views/PrequalFrozenPage"
import PrequalBackPage from "./views/PrequalBackPage"
import PrequalCanceledPage from "./views/PrequalCanceledPage";

import {sessionExpired} from "./store/actions/consumerActions";
import AccountLockedPage from "@wisetack/shared-ui/views/AccountLockedPage";
import Ticker from "@wisetack/shared-ui/components/Ticker";
import ConsumerVerifyEmailPage from "./views/ConsumerVerifyMailPage";
import PlaidError from "./views/PlaidErrorPage";
import RequireLoanAppState from "./components/RequireLoanAppState";

initAmplitude(process.env.REACT_APP_BORROWER_AMPLITUDE_KEY);

const INACTIVITY_TIMEOUT = 1000 * 60 * 30  // 30 min
const ABSOLUTE_TIMEOUT = 1000 * 60 * 60    // 60 min

class App extends Component {

  constructor(props) {
    super(props)
    this.idleTimer = null
  }

  componentDidMount() {
    if (this.props.location.pathname === "/") {
      console.log(this.props.location);
      const params = parse(window.location.search)
      if (params.oauth_state_id) {
        // redirect to Plaid link on OAuth
        localStorage.setItem('oauth_href', window.location.href)
        this.props.history.push("/link_bank_oauth");
        return;
      }
      window.location = "https://www.wisetack.com/";
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage && this.props.errorMessage !== prevProps.errorMessage) {
      if (this.props.errorMessage.includes('Token has expired') || this.props.errorMessage === "Unauthorized.") {
        this.props.history.replace('/session_inactivity_expiration');
      }
    }
  }

  handleOnTicker = () => {
    const session = sessionStorage.getItem('wisetack:session');
    const sessionData = session ? session.split(':') : null
    if (sessionData && sessionData.length === 2) {
      const sessionExpiration = Number(sessionData[1]) + ABSOLUTE_TIMEOUT;
      if (sessionData[0] === this.props.loanAppId && sessionExpiration < Date.now()) {
        this.handleOnAbsoluteExpiration();
      }
    }
  }

  handleOnIdleExpiration = () => {
    this.idleTimer.start();
    if (!this.props.location.pathname.match(/session_.*_expiration/)) {
      logAmplitudeEvent('Session Inactivity Expiration', {loanAppId: this.props.loanAppId})
      this.props.sessionExpired();
      this.props.history.replace('/session_inactivity_expiration');
    }
  }

  handleOnAbsoluteExpiration = () => {
    if (!this.props.location.pathname.match(/session_.*_expiration/)) {
      logAmplitudeEvent('Session Absolute Expiration', {loanAppId: this.props.loanAppId})
      this.props.sessionExpired();
      this.props.history.replace('/session_absolute_expiration');
    }
  }

  render() {
    return (
      <>
        <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={INACTIVITY_TIMEOUT}
            onIdle={this.handleOnIdleExpiration}
        />
        <Ticker timeout={10000} callback={this.handleOnTicker} enabled={true}/>
        <div className="App">
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/blank" component={BlankPage} />
            <RequireLoanAppState path="/choose_plan" component={ConsumerChoosePlanPage}/>
            <RequireLoanAppState path="/review_plan" component={ConsumerReviewPlanPage}/>
            <RequireLoanAppState path="/link_bank" component={ConsumerLinkBankPage}/>
            <RequireLoanAppState path="/link_bank_oauth" component={ConsumerLinkBankPage}/>
            <RequireLoanAppState path="/purchase_complete" component={ConsumerPurchaseCompletePage}/>
            <RequireLoanAppState path="/purchase_complete_lock" component={ConsumerPurchaseCompleteLockPage}/>
            <RequireLoanAppState path="/rejected" component={ConsumerRejectedPage}/>
            <RequireLoanAppState path="/declined" component={ConsumerDeclinedPage}/>
            <RequireLoanAppState path="/merchant_declined" component={MerchantDeclinedPage}/>
            <RequireLoanAppState path="/merchant_expired" component={MerchantExpiredPage}/>
            <RequireLoanAppState path="/declined_max" component={ConsumerDeclinedMaxPage}/>
            <Route path="/error" component={ConsumerErrorPage} />
            <RequireLoanAppState path="/expired" component={ConsumerApplicationExpiredPage}/>
            <RequireLoanAppState path="/timeout" component={ConsumerApplicationExpiredPage}/>
            <RequireLoanAppState path="/pin" component={ConsumerPinPage}/>
            <RequireLoanAppState path="/phone_pin" component={ConsumerPinPage}/>
            <Route path="/home" component={Home} />
            <Route path="/plaid" component={Plaid} />
            <Route path="/plaid_error" component={PlaidError} />
            <Route path="/about" component={About} />
            <RequireLoanAppState path="/phone" component={ConsumerPhonePage}/>
            <RequireLoanAppState path="/phone_retry" component={ConsumerPhonePage}/>
            <RequireLoanAppState path="/phone_return" component={ConsumerPhonePage}/>
            <RequireLoanAppState path="/frozen" component={ConsumerCreditFrozenPage}/>
            <RequireLoanAppState path="/offer_lock" component={OfferLockPage}/>
            <Route path="/account_locked" component={AccountLockedPage} />
            <Route path="/terms" component={ConsumerTermsAgreementPage} />
            <Route path="/privacy" component={ConsumerPrivacyAgreementPage} />
            <Route path="/esign" component={ConsumerEsignAgreementPage} />
            <Route path="/autopay" component={ConsumerAutopayAgreementPage} />
            <RequireLoanAppState path="/autopay_confirm" component={AutopayConfirmationPage}/>
            <Route path="/creditscore" component={ConsumerCreditScoreAgreementPage} />
            <Route path="/loanagreement" component={ConsumerLoanAgreementPage} />
            <Route path="/merchants" component={MerchantAgreementPage} />
            <RequireLoanAppState path="/moreinfo" component={ConsumerMoreInfoRequiredPage}/>
            <RequireLoanAppState path="/purchase_confirm" component={ConsumerConfirmationPage}/>
            <RequireLoanAppState path="/purchase_confirm_future" component={ConsumerConfirmationDeniedFuturePage}/>
            <RequireLoanAppState path="/purchase_confirm_pending" component={ConsumerConfirmationDeniedPastPage}/>
            <RequireLoanAppState path="/purchase_confirm_reminder" component={ConsumerConfirmationReminder}/>
            <RequireLoanAppState path="/purchase_canceled" component={ConsumerCanceledPage}/>
            <RequireLoanAppState path="/purchase_refunded" component={ConsumerRefundedPage}/>
            <Route path="/prequal_pin" component={PrequalPinPage} />
            <Route path="/prequal_entry" component={PrequalEntryPage} />
            <Route path="/prequal_address" component={PrequalAddressPage} />
            <Route path="/prequal_offers" component={PrequalOffersPage} />
            <Route path="/prequal_reapply" component={PrequalReapplyPage} />
            <Route path="/prequal_declined" component={PrequalDeclinedPage} />
            <Route path="/prequal_frozen" component={PrequalFrozenPage} />
            <Route path="/prequal_phone" component={PrequalPhonePage} />
            <Route path="/prequal_back" component={PrequalBackPage} />
            <Route path="/prequal_canceled" component={PrequalCanceledPage} />
            <Route path="/calculator" component={PaymentCalculatorPage} />
            <Route path="/session_inactivity_expiration" component={SessionExpiredPage} />
            <Route path="/session_absolute_expiration" component={SessionExpiredPage} />
            <Route path="/email_confirm" component={ConsumerVerifyEmailPage} />
            <Route path="/:signupId/:prequalId/:checksum" component={PrequalSignupPage} />
            <Route path="/:token" component={ConsumerEntryPage} />
          </Switch>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loanAppId: state.consumer.loanAppId,
  errorMessage: state.consumer.errorMessage
})

export default connect(mapStateToProps, {sessionExpired})(withRouter(App));
