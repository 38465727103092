import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerPurchaseCompletePage.module.scss";
import image1 from "@wisetack/assets/img/check-mark.svg";
import image2 from "@wisetack/assets/img/exclamation-mark.svg";
import moment from "moment";
import {getTruthInLending} from "../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import LearnMoreModal from "@wisetack/shared-ui/components/LearnMoreModal";
import {getVerticalFragment} from "../utils/verticalMessages";

const pageName = "Purchase Complete Page"

class ConsumerPurchaseCompletePage extends React.Component {

  state = {}

  componentDidMount() {
    window.scrollTo(0, 0);

    logAmplitudeEvent(pageName, {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName
    });

    if (
      !this.props.selectedPlan ||
      Object.keys(this.props.selectedPlan).length === 0
    ) {
      this.props.getTruthInLending(this.props.loanAppId, this.props.offerId, false);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      this.props.history.push("/error");
    }
  }

  render() {
    const {
      selectedPlan,
      isLoading,
      errorMessage,
      merchantName,
      autoPayments
    } = this.props;
    Log.info(this.props, `ConsumerPurchaseCompletePage props`);
    let date = "";

    const plan = selectedPlan || {};
    if (plan.starting) {
      const starting = moment(plan.starting, "MM/DD/YYYY");
      if (starting.isValid()) {
        date = starting.format("LL") + ".";
      }
    }

    const LoaderWithMessage = () => {
      if (!isLoading) return null;
      return (
        <div>
          <Loader/>
          <div className={styles.message}>Wait a moment please...</div>
        </div>
      );
    };

    const showContent = !isLoading && !errorMessage;

    return (
      <Container>
        <LearnMoreModal/>
        <PageHeader progress="100%">
          {showContent ? <Fragment>Hooray! Loan Confirmed!</Fragment> : null}
          <Fragment/>
        </PageHeader>
        <LoaderWithMessage/>
        <Error pageName={pageName}>{errorMessage}</Error>
        {showContent ? (
          <>
            <img className={styles.image} src={image1} alt="check-mark"/>
            <div className="row" style={{marginBottom: "5px"}}>
              <div className="col">
                <div className={styles.next}>
                  <h5>What happens next?</h5>
                  <div className={styles.innerText}>
                    • <b>{merchantName}</b> will receive the funds.<br/>
                    • You’ll receive an email from <b>Scratch Servicing</b>, where you can manage your loan and make payments.
                    <span
                      data-toggle="modal"
                      data-target="#learnMoreModal"
                      onClick={() => {
                        logAmplitudeEvent("Opened Learn more link", this.logProps);
                      }}
                    >
                    &nbsp;<b>Learn more.</b>
                    </span><br/>
                    • Your first payment is due on {date}
                  </div>
                  {autoPayments === 'ENABLED' &&
                  <div className={styles.autoPaymentsMessage}>
                    <span className="material-icons" style={{ fontSize: "22px", verticalAlign: "bottom"}}>check_circle_outline</span>
                    <b> You've enabled automatic payments</b>
                  </div>
                  }
                  <div className={styles.support}>
                    <img className={styles.image} src={image2} alt="exclamation-mark"/>
                    <h5>{getVerticalFragment('purchase_complete_header', this.props.vertical)}</h5>
                    <div>
                      {getVerticalFragment('purchase_complete_message', this.props.vertical, {merchantName})}
                      <a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div style={{paddingBottom: "15px"}}/>
        )}
      </Container>
    );
  }
}

ConsumerPurchaseCompletePage.propTypes = {
  selectedPlan: PropTypes.object.isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  selectedPlan: state.consumer.selectedPlan,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  offerId: state.consumer.selectedLoanOfferId || state.consumer.offerId,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  initExpired: state.consumer.initExpired,
  autoPayments: state.consumer.autoPaymentsDecision,
  vertical: state.consumer.vertical
});

export default connect(
  mapStateToProps,
  {getTruthInLending}
)(ConsumerPurchaseCompletePage);
